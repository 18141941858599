@tailwind base;
@tailwind components;
@tailwind utilities;

/* Revert list style or tailwind will override it */
@layer base {
    ul,
    ol {
        list-style: revert;
    }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.grecaptcha-badge {
    visibility: hidden !important;
}
/* swal be top of everything */
.swal2-container {
    z-index: 20000 !important;
}

/* shadow */
.swal2-modal {
    height: 20rem !important;
    width: 40rem !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
}
.Toastify__toast-container--top-center {
    text-align: center;
}
